var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notification row no-gutters flex-nowrap",attrs:{"data-type":_vm.notification.entity.type,"data-type-id":_vm.notification.notification_type_id}},[_c('div',{staticClass:"col"},[(_vm.notification.read == 0)?_c('div',{staticClass:"read"}):_vm._e(),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"d-flex align-items-center relative",class:{ 'col-12' : _vm.notification.group, 'col-auto' : !_vm.notification.group }},[(_vm.notification.group)?[_vm._l((_vm.notificationGroup),function(user){return _c('router-link',{key:user.id,staticClass:"user-profile mb-2",attrs:{"to":{ name: 'web-app-profile', params: { displayName: ("" + (user.displayname)) } }}},[_c('user-picture',{attrs:{"picture":user.photo.url}})],1)}),(_vm.notification.group.from_users.length > _vm.max_notifications)?_c('div',{staticClass:"notification-overflow mb-2"},[_vm._v(" +"+_vm._s(_vm.notification.group.from_users.length - _vm.max_notifications)+" ")]):_vm._e()]:_c('router-link',{staticClass:"user-profile",attrs:{"to":{ name: 'web-app-profile', params: { displayName: ("" + (_vm.notification.from.displayname)) } }}},[_c('user-picture',{attrs:{"picture":_vm.notification.from.avatar}})],1)],2),_c('div',{staticClass:"col d-flex align-items-center"},[_c('p',{staticClass:"notification-text",domProps:{"innerHTML":_vm._s(_vm.notificationText)}})])])]),_c('div',{staticClass:"col-auto action"},[(_vm.notificationEntity.type == 'Memos' || _vm.notificationEntity.type == 'Storms' && !_vm.notificationEntity.is_deleted)?_c('router-link',{attrs:{"to":{
                name: 'memod-reader',
                params: {
                    writer: _vm.notification.reference.writer.displayname,
                    slug: _vm.notificationEntity.slug,
                    id: Number(_vm.notificationEntity.id),
                    partNumber: 1
                }
            }}},[_vm._v(" See memo ")]):_vm._e(),(_vm.notificationEntity.type == 'Comments' && !_vm.notificationEntity.is_deleted)?_c('router-link',{attrs:{"to":{
                name: 'memod-reader',
                params: {
                    writer: _vm.notification.reference.writer.displayname,
                    slug: _vm.notification.reference.slug,
                    id: Number(_vm.notificationEntity.memos_id),
                    partNumber: 1
                },
                hash: _vm.commentHash
            }}},[_vm._v(" See comment ")]):_vm._e(),(_vm.notificationEntity.type == 'UsersList' && !_vm.notificationEntity.is_deleted)?_c('router-link',{attrs:{"to":{
                name: 'web-app-board-view',
                params: {
                    displayName: _vm.notification.reference.writer.displayname,
                    id: Number(_vm.notificationEntity.id),
                }
            }}},[_vm._v(" See board ")]):_vm._e(),(_vm.notificationEntity.type == 'Users' && !_vm.notificationEntity.is_deleted)?_c('follow-entity-button',{attrs:{"entity-id":Number(_vm.notificationEntity.id),"entity-name":_vm.notificationEntity.displayname,"is-following":Number(_vm.is_following),"entity":"users"},on:{"toggled-follow":function (value) { return _vm.is_following = value; }}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }