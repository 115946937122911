<template>
    <div
        :data-type="notification.entity.type"
        :data-type-id="notification.notification_type_id"
        class="notification row no-gutters flex-nowrap"
    >
        <div class="col">
            <div v-if="notification.read == 0" class="read" />
            <div class="row no-gutters">
                <div :class="{ 'col-12' : notification.group, 'col-auto' : !notification.group }" class="d-flex align-items-center relative">
                    <template v-if="notification.group">
                        <router-link
                            v-for="user in notificationGroup"
                            :key="user.id"
                            class="user-profile mb-2"
                            :to="{ name: 'web-app-profile', params: { displayName: `${user.displayname}` } }"
                        >
                            <user-picture :picture="user.photo.url" />
                        </router-link>
                        <div v-if="notification.group.from_users.length > max_notifications" class="notification-overflow mb-2">
                            +{{ notification.group.from_users.length - max_notifications }}
                        </div>
                    </template>
                    <router-link v-else class="user-profile" :to="{ name: 'web-app-profile', params: { displayName: `${notification.from.displayname}` } }">
                        <user-picture :picture="notification.from.avatar" />
                    </router-link>
                </div>
                <div class="col d-flex align-items-center">
                    <p class="notification-text" v-html="notificationText" />
                </div>
            </div>
        </div>
        <div class="col-auto action">
            <router-link
                v-if="notificationEntity.type == 'Memos' || notificationEntity.type == 'Storms' && !notificationEntity.is_deleted"
                :to="{
                    name: 'memod-reader',
                    params: {
                        writer: notification.reference.writer.displayname,
                        slug: notificationEntity.slug,
                        id: Number(notificationEntity.id),
                        partNumber: 1
                    }
                }"
            >
                See memo
            </router-link>
            <router-link
                v-if="notificationEntity.type == 'Comments' && !notificationEntity.is_deleted"
                :to="{
                    name: 'memod-reader',
                    params: {
                        writer: notification.reference.writer.displayname,
                        slug: notification.reference.slug,
                        id: Number(notificationEntity.memos_id),
                        partNumber: 1
                    },
                    hash: commentHash
                }"
            >
                See comment
            </router-link>
            <router-link
                v-if="notificationEntity.type == 'UsersList' && !notificationEntity.is_deleted"
                :to="{
                    name: 'web-app-board-view',
                    params: {
                        displayName: notification.reference.writer.displayname,
                        id: Number(notificationEntity.id),
                    }
                }"
            >
                See board
            </router-link>
            <follow-entity-button
                v-if="notificationEntity.type == 'Users' && !notificationEntity.is_deleted"
                :entity-id="Number(notificationEntity.id)"
                :entity-name="notificationEntity.displayname"
                :is-following="Number(is_following)"
                entity="users"
                @toggled-follow="(value) => is_following = value"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "Notification",
    components: {
        UserPicture: () => import(/* webpackChunkName: "user-picture" */ "@c/atoms/user-picture"),
        FollowEntityButton: () => import(/* webpackChunkName: "follow-user-button" */ "@c/atoms/follow-entity-button")
    },
    props: {
        notification: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            is_following: 0,
            max_notifications: 7
        }
    },
    computed: {
        notificationEntity() {
            if (this.notification.entity.type) {
                return this.notification.entity
            } else {
                return {}
            }
        },
        notificationText() {
            const text = this.notification.content.trim();
            const textRegexp = new RegExp(/([^.!?])$/);
            const endingRegexp = new RegExp(/[a-zA-Z>]$/);
            const textMatch = text.match(textRegexp);
            const endingMatch = text.match(endingRegexp);
            let sentence = text;

            if (textMatch !== null) {
                if (endingMatch === null) {
                    sentence = sentence.replace(textRegexp, ".");
                } else {
                    sentence += ".";
                }
            }

            return `<strong>${this.notification.from.displayname}</strong> ${sentence} ${this.notification.formatted_date}`;
        },
        commentHash() {
            let hash = "#comment-";

            if (Number(this.notificationEntity.parent_id)) {
                hash += `${this.notificationEntity.parent_id}-reply-`;
            }

            return hash += this.notificationEntity.id;
        },
        notificationGroup() {
            if (this.notification.group) {
                return this.notification.group.from_users.filter((_notification, index) => index < this.max_notifications);
            } else {
                return false;
            }
        }
    },
    created() {
        if (this.notificationEntity.type == "Users") {
            this.is_following = this.notification.from.is_following;
        }
    }
}
</script>

<style lang="scss" scoped>
.notification {
    padding: 15px 0;
    display: flex;
    border-bottom: 1px solid rgba(84, 84, 88, 0.65);

    .read {
        width: 8px;
        height: 8px;
        background: #05A88F;
        border-radius: 100%;
        margin-right: 10px;
        position: absolute;
        top: 30px;
        left: -15px
    }

    .user-profile {
        margin-left: -10px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 12px;
        }
    }

    .notification-overflow {
        width: 60px;
        height: 60px;
        background-color: #3A3A3C;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        margin-left: -10px;
    }

    .notification-text {
        @extend .body-text;
        margin-bottom: 0;
        color: #8d8d95;

        /deep/ strong {
            color: white;
            text-transform: capitalize;
        }

        /deep/ a {
            color: $primary-color;
        }
    }

    .action {
        @extend .body-text;
        color: $primary-color;
        display: flex;

        > * {
            padding: 0 25px;
            color: inherit;
            display: flex;
            align-items: center;

            @media(max-width: $md) {
                padding: 0 10px;
            }
        }

        /deep/ .follow-button {
            padding: 0;
            align-items: initial;

            button {
                background-color: transparent;
                width: 100%;
                height: initial;
                border: 0;
                padding: 0 25px;
                display: flex;
                align-items: center;

                @media(max-width: $md) {
                    padding: 0 10px;
                }

                &.btn {
                    color: $primary-color;

                    &.btn-selected {
                        color: #8d8d95;
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}
</style>
